/**
 * TOP Frontend
 * This is an **example** API to demonstrate features of the OpenAPI specification.  # Introduction  This API definition is intended to be a good starting point for describing your API in   [OpenAPI/Swagger format](https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.2.md).  It also demonstrates features of the [create-openapi-repo](https://github.com/Redocly/create-openapi-repo) tool and   the [Redoc](https://github.com/Redocly/Redoc) documentation engine. Beyond the standard OpenAPI syntax, we use a few   [vendor extensions](https://github.com/Redocly/Redoc/blob/master/docs/redoc-vendor-extensions.md).   # OpenAPI Specification  The goal of The OpenAPI Specification is to define a standard, language-agnostic interface to REST APIs which  allows both humans and computers to discover and understand the capabilities of the service without access to source  code, documentation, or through network traffic inspection. When properly defined via OpenAPI, a consumer can   understand and interact with the remote service with a minimal amount of implementation logic. Similar to what  interfaces have done for lower-level programming, OpenAPI removes the guesswork in calling the service. 
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CopTOFEConfiguration { 
    /**
     * To customize frontend welcome page. Uses TOFECustomizationEnum values.
     */
    welcomePage: string;
    /**
     * To customize frontend UX. Uses TOFECustomizationEnum values.
     */
    uxDefinition: string;
    /**
     * To customize frontend DocumentCaptureProvider. Uses TOFEDocumentCaptureProviderEnum values.
     */
    documentCaptureProvider: CopTOFEConfiguration.DocumentCaptureProviderEnum;
    /**
     * true if TOFE runs only on mobile device.
     */
    mobileOnly: boolean;
    /**
     * Indication if the back of the passport is required.
     */
    passportBackRequired?: boolean;
    /**
     * To show qr code corresponding to the tofe url.
     */
    qrCode?: boolean;
    /**
     * In the oneshot task, it also acquires selfie image.
     */
    requireDocuments?: boolean;
    /**
     * In the document-capture task, it also acquires selfie image.
     */
    selfie?: boolean;
    /**
     * In the OCR form (document-capture task), collects more personal data if true.
     */
    extendedPersonData?: boolean;
    /**
     * In the persondata task, shows a disclaimer on the mobile number if true.
     */
    mobileNumberDisclaimer?: boolean;
    /**
     * Additional information associated with the configuration.
     */
    additionalInfo?: { [key: string]: string; };
    /**
     * In the complete task, shows the documents if true.
     */
    onCompleteShowDocuments?: boolean;
    /**
     * To customize frontend welcome page for eid. Uses TOFECustomizationEnum values.
     */
    eidWelcomePage?: boolean;
}
export namespace CopTOFEConfiguration {
    export type DocumentCaptureProviderEnum = 'DEFAULT' | 'VERIDAS';
    export const DocumentCaptureProviderEnum = {
        Default: 'DEFAULT' as DocumentCaptureProviderEnum,
        Veridas: 'VERIDAS' as DocumentCaptureProviderEnum
    };
}


